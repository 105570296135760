import * as React from "react";

import * as styles from "./styles.module.css";
import Dish from "./Dish/Dish";
import { apiUrl } from "../../../app.config";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import phoneIcon from "../../../images/icons/phone.svg";

import DropdownIcon from "../../../images/icons/dropdown.svg";
import ErrorIcon from "../../../images/icons/error.svg";
import useFetch from "../../../hooks/useFetch";
import ButtonHover from "../../ui/ButtonHover/ButtonHover";
import { Link } from "gatsby";

const getUniqueValues = (value, index, self) => {
  return self.indexOf(value) === index;
};

const cartReducer = (cart, action) => {
  switch (action.type) {
    case "ADD":
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].name === action.dish.name) {
          const newCart = [...cart];

          if (newCart[i].quantity >= 15) {
            Toastify({
              text: "Maximale Anzahl erreicht",
              duration: 2000,
              close: false,
              position: "center",
              gravity: "top",
              style: {
                background: "#ee9548",
                color: "black",
              },
            }).showToast();
            return [...newCart];
          }

          newCart[i].price = newCart[i].price + action.dish.price;
          newCart[i].quantity = newCart[i].quantity + 1;

          // show Toast message
          if (action.showToast) {
            Toastify({
              text: "Zu Merkliste hinzugefügt",
              duration: 2000,
              close: false,
              position: "center",
              gravity: "top",
              style: {
                background: "#ee9548",
                color: "black",
              },
            }).showToast();
          }

          return [...newCart];
        }
      }
      // show Toast message
      if (action.showToast) {
        Toastify({
          text: "Zu Merkliste hinzugefügt",
          duration: 2000,
          close: false,
          position: "center",
          gravity: "top",
          style: {
            background: "#ee9548",
            color: "black",
          },
        }).showToast();
      }

      return [...cart, { name: action.dish.name, quantity: 1, price: action.dish.price }];
      break;
    case "REMOVE":
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].name === action.item.name) {
          const newCart = [...cart];

          if (newCart[i].quantity === 1) {
            newCart.splice(i, 1);
            return [...newCart];
          }

          const singlePrice = action.item.price / action.item.quantity;
          if (newCart[i].quantity > 0) {
            newCart[i].price = newCart[i].price - singlePrice;
            newCart[i].quantity = newCart[i].quantity - 1;
          }

          return [...newCart];
        }
      }
      break;
  }
};

export default function MenuSection() {
  const [cart, dispatchCart] = React.useReducer(cartReducer, []);

  const [showOverview, setShowOverview] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isCartOpen, setIsCartOpen] = React.useState(false);
  const [isPriceSelectorOpen, setIsPriceSelectorOpen] = React.useState(false);
  const [isAddonSelectorOpen, setIsAddonSelectorOpen] = React.useState(false);
  const [selectedDish, setSelectedDish] = React.useState(null);

  const [categories, setCategories] = React.useState(null);
  const [dishes, setDishes] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState({ id: 1, name: "Pizza" });
  const [initialLoading, setInitialLoading] = React.useState(true);

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);


  const {
    loading: loadingCategories,
    data: loadedCategories,
    error: categoriesError,
    success: categoriesSuccess,
    fetchData: fetchCategories,
  } = useFetch(`${apiUrl}/menu/categories`);

  React.useEffect(() => {
    fetchCategories();
  }, []);

  React.useEffect(() => {
    if (isCartOpen) {
      document.getElementsByTagName("body")[0].style.height = "100vh";
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.height = "auto";
      document.getElementsByTagName("body")[0].style.overflowY = "auto";
    }
  }, [isCartOpen]);

  React.useEffect(async () => {

    if (categoriesSuccess && loadedCategories) {
      setLoading(true);
      setCategories(loadedCategories);

      // fetch all dishes at once to avoid loading
      let allDishes = [];
      try {
        for (let category of loadedCategories) {
          const response = await fetch(`${apiUrl}/menu/dishes/${category.id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          const data = await response.json();

          allDishes.push({ id: category.id, dishes: [...data] });
        }

        setDishes(allDishes);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }

      // set selected category to requested category from url
      const hash = window.location.hash.substring(1);

      if (hash) {
        if (hash === "all") {
          setShowOverview(true);
          return;
        }
        setShowOverview(false);
        const category = loadedCategories.filter((category) => category.id == hash);

        if (category.length > 0) {
          setSelectedCategory(category[0]);
        }
      }
      setInitialLoading(false);
    }
  }, [categoriesSuccess]);

  const handleCategorySelected = (category) => {
    setIsMenuOpen(false);
    setSelectedCategory(category);
  };

  const selectedDishes = dishes
    ? dishes.filter((dish) => dish.id === selectedCategory.id)[0].dishes
    : [];

  const messageText = `Ich würde gerne folgende Bestellung aufgeben:\n${cart
    .map((x) => `${x.quantity}x ${x.name}`)
    .join("\n")}`;
  const whatsappUrl = `https://wa.me/436643711199?text=${encodeURI(messageText)}`;

  const genericPrices = selectedCategory.generic_prices;
  const getFormattedGenericPrice = () => {
    const lowestValue = Math.min(...genericPrices.map((genericPrice) => genericPrice.price));
    const highestValue = Math.max(...genericPrices.map((genericPrice) => genericPrice.price));

    const priceRange =
      lowestValue.toFixed(2).toString().replace(".", ",") +
      " - " +
      highestValue.toFixed(2).toString().replace(".", ",");

    return priceRange;
  };

  const dishSelectedHandler = (dish) => {
    if (genericPrices) {
      setIsPriceSelectorOpen(true);
      setSelectedDish(dish);

      return;
    }

    addItemToCart(dish);

    if (dish.addons.length > 0) {

      setIsAddonSelectorOpen(true);

      if (dish.doAddonsHaveHeadlines)
        return;

      const compare = (a, b) => {
        if (a.category < b.category)
          return -1;
        if (a.category > b.category)
          return 1;
        if (a.isheadline)
          return -1;
        return 0;
      }

      let findCategories = [];
      dish.addons.forEach(addon => {
        const category = addon.category;
        if (!findCategories.includes(category)) {
          findCategories.push(category);
          dish.addons.push({
            category: category,
            isheadline: true
          });
        }
      });

      dish.addons.sort(compare);
      dish.doAddonsHaveHeadlines = true;

      setSelectedDish(dish);

    }

  };
  const priceSelectedHandler = (genericPrice) => {
    addItemToCart({
      ...selectedDish,
      price: genericPrice.price,
      name: `${selectedDish.name} (${genericPrice.name})`,
    });
    setIsPriceSelectorOpen(false);

    if (selectedDish.addons.length > 0) {

      setIsAddonSelectorOpen(true);

      if (selectedDish.doAddonsHaveHeadlines)
        return;

      const compare = (a, b) => {
        if (a.category < b.category)
          return -1;
        if (a.category > b.category)
          return 1;
        if (a.isheadline)
          return -1;
        return 0;
      }

      let findCategories = [];
      selectedDish.addons.forEach(addon => {
        const category = addon.category;
        if (!findCategories.includes(category)) {
          findCategories.push(category);
          selectedDish.addons.push({
            category: category,
            isheadline: true
          });
        }
      });

      selectedDish.addons.sort(compare);
      selectedDish.doAddonsHaveHeadlines = true;

    }
  };

  const addItemToCart = (dish) => {
    dispatchCart({ type: "ADD", dish: dish, showToast: true });
  };

  React.useEffect(() => {
    const open = isPriceSelectorOpen || isCartOpen || isAddonSelectorOpen;
    document.body.style.overflow = open ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    }
  }, [isPriceSelectorOpen, isCartOpen, isAddonSelectorOpen]);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <div>
          <img className={styles.logo} src='/icons/icon-144x144.png' alt='Logo' />
        </div>
        <Link className={styles.logotext} to='/'>
          Kapfenstein Imbiss
        </Link>
      </div>
      {showOverview && (
        <>
          <div className={styles.content}>
            <div className={styles.text}>
              <span className={styles.topHeading}>Pizzeria</span>
              <h1 className={styles.heading}>Kapfenstein Imbiss</h1>
              <p>
                Hausgemachte Pizzen, Pasta, Burger und vieles mehr. Entdecken Sie unser Angebot.
              </p>
            </div>
          </div>
          <div className={styles.overview}>
            <h2>Speisen</h2>
            {categories.map((category, index) => (
              <Link
                key={category.id}
                className={styles.link}
                to={`/speisekarte#${category.id}`}
                onClick={() => {
                  setShowOverview(false);
                  setInitialLoading(false);
                  setSelectedCategory(category);
                }}>
                {category.name}
              </Link>
            ))}
          </div>
        </>
      )}
      {!showOverview && (
        <>
          {
            !initialLoading &&
            <button className={styles.cartButton} onClick={() => setIsCartOpen(true)}>
              Merkliste{" "}
              <span className={styles.amount}>
                {cart.reduce((total, item) => (total = total + item.quantity), 0)}
              </span>
            </button>
          }
          {isCartOpen && (
            <Popup title='Merkliste' onClose={() => setIsCartOpen(false)}>
              <div className={styles.items}>
                {cart.length === 0 && (
                  <div className={styles.emptyCart}>
                    Die Merkliste ist leer. <br />
                    <br /> Klicke auf ein Gericht, um es der Merkliste hinzuzufügen.
                  </div>
                )}
                {cart.map((item) => (
                  <div className={styles.item} key={item.name}>
                    <span className={styles.title}>{item.name}</span>
                    <span className={styles.itemcontainer}>
                      <div className={styles.quantityContainer}>
                        <button
                          className={styles.quantityButton}
                          onClick={() => dispatchCart({ type: "REMOVE", item: item })}>
                          -
                        </button>
                        <div>{item.quantity}x</div>
                        <button
                          className={styles.quantityButton}
                          onClick={() =>
                            dispatchCart({
                              type: "ADD",
                              dish: { ...item, price: item.price / item.quantity },
                            })
                          }>
                          +
                        </button>
                      </div>
                      <span className={styles.price}>
                        € {item.price.toFixed(2).toString().replace(".", ",")}
                      </span>
                    </span>
                  </div>
                ))}
                <div className={styles.placeholder} />
              </div>
              {cart.length > 0 && (
                <div className={styles.buttonContainer}>
                  <ButtonHover href='tel:+436643711199' size='LARGE'>
                    Anrufen
                  </ButtonHover>
                  <ButtonHover href={whatsappUrl} useGatsbyLink={false} targetBlank size='LARGE'>
                    WhatsApp
                  </ButtonHover>
                </div>
              )}
            </Popup>
          )}
          {isPriceSelectorOpen && (
            <Popup title='Größe wählen' onClose={() => setIsPriceSelectorOpen(false)}>
              <div className={styles.prices}>
                {genericPrices.map((genericPrice) => (
                  <Dish
                    key={genericPrice.name}
                    onClick={() => priceSelectedHandler(genericPrice)}
                    title={genericPrice.name}
                    price={genericPrice.price.toFixed(2).toString().replace(".", ",")}
                  />
                ))}
              </div>
            </Popup>
          )}
          {isAddonSelectorOpen && (
            <Popup title='Beilage wählen' onClose={() => setIsAddonSelectorOpen(false)}>
              <div className={styles.prices}>
                {selectedDish.addons.map((addon) => (
                  addon.isheadline ?
                    <h2 className={styles.categoryheadline}>
                      {addon.category}
                    </h2>
                    :
                    <Dish
                      key={addon.name}
                      onClick={() => {
                        addItemToCart({
                          name: `${addon.name} (Beilage ${selectedDish.name} ${selectedCategory.name})`,
                          quantity: 1,
                          price: addon.price,
                        });
                        setIsPriceSelectorOpen(false);
                      }}
                      title={addon.name}
                      price={addon.price.toFixed(2).toString().replace(".", ",")}
                    />
                ))}
              </div>
            </Popup>
          )}
          <div className={styles.content}>
            <div className={styles.text}>
              <span className={styles.topHeading}>Pizzeria</span>
              <h1 className={styles.heading}>Kapfenstein Imbiss</h1>
              <p>
                Hausgemachte Pizzen, Pasta, Burger und vieles mehr. Entdecken Sie unser Angebot.
              </p>
            </div>
          </div>
          <div className={styles.menu}>
            <div className={styles.selectorContainer}>
              {
                !loading && !disabled &&
                <>
                  <span>Ich mag</span>
                  <div
                    className={`${styles.selector} ${loadingCategories && styles.loading}`}
                    onClick={() => setIsMenuOpen(true)}>
                    {initialLoading && <span>...</span>}
                    {!initialLoading && (
                      <>
                        <span className={styles.selectedCategory}>{selectedCategory.name}</span>
                        <img src={DropdownIcon} />
                      </>
                    )}
                  </div>
                </>
              }
              {isMenuOpen && (
                <>
                  <div className={styles.menuOverlay} onClick={() => setIsMenuOpen(false)}></div>
                  <div className={styles.dropdown}>
                    {categories.map((category) => (
                      <span
                        key={category.id}
                        className={styles.category}
                        onClick={() => handleCategorySelected(category)}>
                        {category.name}
                      </span>
                    ))}
                  </div>
                </>
              )}
            </div>
            {(error || categoriesError) && (
              <div className={styles.error}>
                <img src={ErrorIcon} alt='Location Icon' />
                <span>
                  Ein Fehler ist aufgetreten. <br />
                  Versuchen Sie es bitte später erneut.
                </span>
              </div>
            )}
            {disabled && (
              <div className={styles.error}>
                <img src={ErrorIcon} alt='Location Icon' />
                <span>
                  Die Speisekarte wird zurzeit überarbeitet und ist bald wieder verfügbar.
                  Versuchen Sie es bitte später erneut.
                </span>
              </div>
            )}
            <div className={styles.dishes}>
              {!disabled && selectedDishes.map((dish) => (
                <Dish
                  key={dish.id}
                  onClick={() => dishSelectedHandler(dish)}
                  title={dish.name}
                  price={
                    genericPrices
                      ? getFormattedGenericPrice()
                      : dish.price.toFixed(2).toString().replace(".", ",")
                  }
                  description={dish.description}
                />
              ))}
            </div>
            {!disabled && loading && <Loader />}
          </div>
        </>
      )}
    </div>
  );
}

const Popup = (props) => {
  return (
    <Overlay>
      <div className={styles.popup}>
        <div className={styles.popupHeader}>
          <h2>{props.title}</h2>
          <span className={styles.closeButton} onClick={props.onClose}>
            &#10799;
          </span>
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </Overlay>
  );
};

const Overlay = (props) => {
  return <div className={styles.overlay}>{props.children}</div>;
};

const Loader = () => {
  return <div className={styles.loader}></div>;
};
