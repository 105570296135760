import * as React from "react";
import MenuSection from "../components/sections/MenuSection/MenuSection";
import "./global.css";

import * as styles from "./speisekarte.module.css";

const SpeisekartePage = () => {
  return (
    <div className={styles.container}>
      <MenuSection></MenuSection>
    </div>
  );
};

export default SpeisekartePage;
