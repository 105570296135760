import * as React from "react";

import * as styles from "./styles.module.css";

export default function Dish(props) {
  return (
    <div className={styles.container} onClick={() => props.onClick()}>
      <div className={styles.row}>
        <span className={styles.title}>{props.title}</span>
        <span className={styles.price}>€ {props.price}</span>
      </div>
      {props.category && <span className={styles.category}>{props.category}</span>}
      <span className={styles.description}>{props.description}</span>
    </div>
  );
}
