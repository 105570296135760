import { useState } from "react";

const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // success
        setData(data);
        setLoading(false);
        setSuccess(true);

        // reset to remove message
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      })
      .catch(() => {
        setLoading(false);
        setError(true);

        // reset to remove message
        setTimeout(() => {
          setError(false);
        }, 2000);
      });
  };

  return {
    fetchData,
    loading,
    success,
    error,
    data,
  };
};

export default useFetch;
