// extracted by mini-css-extract-plugin
export var amount = "styles-module--amount--xib+s";
export var buttonContainer = "styles-module--buttonContainer---IpWO";
export var cartButton = "styles-module--cartButton--JvWPL";
export var category = "styles-module--category--lDNeQ";
export var categoryheadline = "styles-module--categoryheadline--dT0uB";
export var closeButton = "styles-module--closeButton--xqria";
export var container = "styles-module--container--CiHuQ";
export var content = "styles-module--content--nKXmT";
export var dishes = "styles-module--dishes--XyO7n";
export var dropdown = "styles-module--dropdown--HsOmK";
export var emptyCart = "styles-module--emptyCart--BKV-3";
export var error = "styles-module--error--likmD";
export var heading = "styles-module--heading--rweDr";
export var item = "styles-module--item--ne4E6";
export var itemcontainer = "styles-module--itemcontainer--IgAqI";
export var items = "styles-module--items--OmKlt";
export var link = "styles-module--link--pZwES";
export var loader = "styles-module--loader--w0y2W";
export var loading = "styles-module--loading--MiupR";
export var logo = "styles-module--logo--EVT7j";
export var logoContainer = "styles-module--logoContainer--n7gqF";
export var logotext = "styles-module--logotext--vm6RR";
export var menu = "styles-module--menu--cssvV";
export var menuOverlay = "styles-module--menuOverlay--AsObD";
export var overlay = "styles-module--overlay--iy2GG";
export var overview = "styles-module--overview--zlV6o";
export var placeholder = "styles-module--placeholder--NzO9r";
export var popup = "styles-module--popup--RKKzq";
export var popupHeader = "styles-module--popupHeader--yNr9t";
export var price = "styles-module--price--Nv1pa";
export var prices = "styles-module--prices--rFp8P";
export var quantityButton = "styles-module--quantityButton--E64u7";
export var quantityContainer = "styles-module--quantityContainer--Ntnsq";
export var selector = "styles-module--selector--od6vU";
export var selectorContainer = "styles-module--selectorContainer--Acc5x";
export var spin = "styles-module--spin--Rra3d";
export var text = "styles-module--text--a8331";
export var title = "styles-module--title--Zjwp8";
export var titleContainer = "styles-module--titleContainer--o7XBD";